
import Vue from 'vue'
import BoosterPicture from '../../node_modules/nuxt-booster/runtime/components/BoosterPicture.vue'
export default Vue.extend({
  name: 'Event',
  components: {
    BoosterPicture
  },
  props: {
    event: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    getFormattedDate() {
      const dayOfWeek = this.$dayjs(this.event.startDate).locale(this.$i18n.locale).format('dddd')
      const formattedTime = this.$dayjs(this.event.startDate).locale(this.$i18n.locale).format('HH:mm')
      return formattedTime ? this.$t('blocks.timeFormat', { day: dayOfWeek, time: formattedTime }) : ''
    },
    getInfo() {
      if (this.event.info) {
        switch (this.event.info) {
        case 1:
          const startDate = this.$dayjs(this.event.startDate)
          const today = this.$dayjs()
          const diffDays = startDate.diff(today, 'day')
          return this.$tc('blocks.daysRemaining', diffDays, { count: diffDays })
        case 2:
          // Restituisci il messaggio che i posti stanno per terminare
          return this.$t('blocks.spotsAlmostGone')
        case 3:
          // Restituisci il messaggio Sold Out
          return this.$t('blocks.soldOut')
        default:
          return ''
        }
      }
      return ''
    }
  },
  methods: {
    getMedia(url, date) {
      const parts = url.split('/')
      const updatedUrl = parts.slice(4).join('/')
      return '/' + updatedUrl + '?updateTs=' + date
    },
    openLink(link) {
      // this.track()
      // Apri il link in una nuova scheda del browser
      const newWindow = window.open(link, '_blank')
      if (newWindow) {
        newWindow.opener = null // Evita che la finestra aperta possa accedere all'opener
        newWindow.focus() // Porta la nuova scheda in primo piano
      } else {
        // Gestisci il blocco dei popup
        alert('I popup sono bloccati dal tuo browser. Abilita i popup per aprire il link.')
      }
    },
    track () {
      this.$gtm.push({
        event: 'click_event',
        event_data: {
          event_name: this.title,
          event_url: this.link,
          is_special: false,
          page_url: this.$router.currentRoute.fullPath,
          page_title: this.title
        }
      })
    }
  }
})
